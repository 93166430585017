<template>
  <Layout>

    <h1 class="uk-heading-small" id="content">Users</h1><router-link :to="'/users/create'">Create</router-link>
    <table class="uk-table uk-table-divider">
      <caption></caption>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Actions</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" v-for="users in users" :key="users.name">
          <td>{{ users.name }}</td>
          <td>{{ users.email }}</td>

          <td><router-link :to="'/users/' + users.id">View</router-link></td>
          <td><router-link :to="'/users/password/' + users.id">Password</router-link></td>
          <!-- and so on -->
        </tr>
      </tbody>
    </table>

  </Layout>
</template>



<script>

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Layout from '../layouts/private.vue'

export default {
  name: "Users Overview",
  components: { Layout },
  data() {
    return {
      content: "Users",
      users: "",
    };
  },
  mounted() {
    UserService.getUsers().then(
      (response) => {
        this.users = response.data.results;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  },
};

</script>
